<template>
  <div>
    <el-row :gutter="20" class="search-box">
      <el-col :span="4">
        <el-input
          placeholder="电池macid"
          clearable
          v-model="query.batteryMacId"
        ></el-input>
      </el-col>

      <el-col :span="4" v-operation>
        <el-select
          v-model="query.operationId"
          clearable
          placeholder="代理商名称"
        >
          <el-option
            v-for="(item, index) in agentList.list"
            :label="item.operationName"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-col>

      <el-col :span="4">
        <el-input
          placeholder="备注"
          clearable
          v-model="query.remark"
        ></el-input>
      </el-col>

      <el-col :span="4">
        <el-button type="primary" @click="getList(1)">查询</el-button>
      </el-col>
    </el-row>

    <el-table
      border
      :data="dataSource"
      class="page-top-space"
      row-class-name="table-row"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column label="柜子编码" prop="stationMacId"></el-table-column>
      <el-table-column label="电池编码" prop="batteryMacId"></el-table-column>

      <el-table-column label="仓门" prop="doorId"></el-table-column>

      <el-table-column label="信息" prop="info"> </el-table-column>
       
      <el-table-column label="操作">
        <template #default="scope">
          <span
            class="option option-primary"
            @click="showMap(scope.row)"
            v-auth="this.per.SHOW_BATTERY_DETAIL"
          >
            解禁
          </span>
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      layout="total, prev, pager, next"
      background
      :total="total"
      :page-size="pages.pageSize"
      :current-page="pages.pageIndex"
      @current-change="getList"
    ></el-pagination>
  </div>
</template>

<script>
import { fetchStationCabinetBanList } from "@/api/station";
import { mapState } from "vuex"; 
export default {
  computed: mapState({
    agentList: (state) => {
      return state.agent.agentList || { list: [], map: {} };
    },
  }),
  data() {
    return {
      dataSource: [],

      pages: {
        pageIndex: 1,
        pageSize: 10,
      },
      query: {
        operationId: -1,
      },
      modalData: {},
      modalType: "add",
      total: 0,
    };
  },
  methods: {
    getList(current) {
      if (!this.validatenull(current)) {
        this.pages.pageIndex = current;
      }
      fetchStationCabinetBanList({ ...this.query, ...this.pages }).then(
        (res) => {
          this.dataSource = res.data.data.records;
          this.total = res.data.data.total;
          this.dataSource.map((item) => {
            item.operationName = this.agentList.map[item.operationId];
          });
        }
      );
    },
  },

  mounted() {
    this.getList(1);
  },
};
</script>
